/* eslint-disable no-async-promise-executor */
import _ from 'lodash'
import axios from '@/utils/axios'

export const getAll = async () => {
  const response = await axios.get(`/organisation/unit/roleprofile/input/`)

  return response
}

export const createOne = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.post(`/organisation/unit/roleprofile/input/`, formData)

  return response
}

export const updateOne = async payload => {
  const formData = new FormData()

  _.each(payload, (value, key) => {
    value && formData.append(key, value)
  })

  const response = await axios.patch(
    `/organisation/unit/roleprofile/input/${payload.id}/`,
    formData
  )

  return response
}

export const deleteOne = async ({ id }) => {
  const response = await axios.delete(`/organisation/unit/roleprofile/input/${id}/`)

  return response
}
